
import { Link , useLocation} from 'react-router-dom';
import './Indian.css';
import React, { useState } from 'react';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';

const TableComponentLeft = ({ leftColumnData }) => {
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            
          </tr>
        </thead>
        <tbody>
          {leftColumnData.map((row, index) => (
            <tr key={index}>
              <td>{row.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const TableComponentRight = ({ rightColumnData }) => {
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            
          </tr>
        </thead>
        <tbody>
          {rightColumnData.map((row, index) => (
            <tr key={index}>
              <td>{row.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const Indian = () => {
 const initialLeftColumnData = [
    { name: 'Indian Institute of Technology, Gandhinagar, Gujarat' },
    { name: 'Indian Institute of Management, Ahmedabad, Gujarat' },
    { name: 'Indian Institute of Information Technology, Vadodara, Gujarat' },
    { name: 'Institute of Infrastructure Technology Research and Management, Ahmedabad, Gujarat' },
    { name: 'Charotar University Of Science & Technology, Anand, Gujarat' },
    { name: 'Anant National University, Ahmedabad, Gujarat' },
    { name: 'Indian Institute Of Technology, Hyderabad' },
    { name: 'Indian Institute of Technology, Dharwad, Karnataka' },
    { name: 'Indian Institute of Management, Bangalore, Karnataka' },
    { name: 'Indian Statistical Institute, Bangalore, Karnataka' },
    { name: 'Tata Institute of Fundamental Research Centre for Applicable Mathematics, Bangalore, Karnataka' },
    { name: 'National Centre for Biological Sciences, Bangalore, Karnataka' },
    { name: 'ERNET Regional Center, Bangalore, Karnataka' },
    { name: 'ERNET Office, Bangalore, Karnataka' },
    { name: 'Raman Research Institute, Bangalore, Karnataka' },
    { name: 'International Centre for Theoretical Sciences-Tata Institute of Fundamental Research Centre for Applicable Mathematics, Bangalore, Karnataka' },
    { name: 'Computer Centre-International Centre for Theoretical Sciences-Tata Institute of Fundamental Research Centre for Applicable Mathematics, Bangalore, Karnataka' },
    { name: 'Indian Institute Of Sciences, Bangalore, Karnataka' },
    { name: 'Jawaharlal Nehru Centre for Advanced Scientific Research, Bangalore, Karnataka' },
    { name: 'Computer Centre Jawaharlal Nehru Centre for Advanced Scientific Research, Bangalore, Karnataka' },
    { name: 'Institute for Stem Cell Science and Regenerative Medicine, Bangalore, Karnataka' },
    { name: 'Azim Premji University, Bangalore, Karnataka' },
    { name: 'Indian Institute of Astrophysics, Bangalore, Karnataka' },
    { name: 'Manipal Academy of Higher Education, Manipal, Karnataka' },
    { name: 'International Institute Of Information Technology, Bangalore (N), Karnataka' },
    { name: 'Indian Institute of Technology, Madras, Tamil Nadu' },
    { name: 'Conference hall, IIT, Madras, Tamil Nadu' },
    { name: 'Indian Institute of Information Technology, Design & Manufacturing, Chennai, Tamil Nadu' },
    { name: 'ERNET India, Chennai, Tamil Nadu' },
    { name: 'SRM University, Chennai, Tamil Nadu' },
    { name: 'Computer Centre, SRM University, Chennai, Tamil Nadu' },
    { name: 'Karunya Institute of Technology and Sciences, Tamil Nadu' },
    { name: 'B.S. Abdur Rahman Crescent Institute of Science & Technology, Chennai, Tamil Nadu' },
    { name: 'Agricultural College & Research Institute (AC&RI), Tamil Nadu Agricultural University, Coimbatore, Tamil Nadu' },
    { name: 'School of Post Graduate Studies (SPGS), Tamil Nadu Agricultural University, Coimbatore, Tamil Nadu' },
    { name: 'Agricultural Engineering College & Research Institute (AEC&RI)-Tamil Nadu Agricultural University, Coimbatore, Tamil Nadu' },
    { name: 'Horticultural College & Research Institute (HC&RI)-Tamil Nadu Agricultural University, Coimbatore, Tamil Nadu' },
    { name: 'The Institute Of Mathematical Sciences, Chennai, Tamil Nadu' },
    { name: 'Agricultural College and Research Institute, Eachangkottai, Thanjavur, Tamil Nadu' },
    { name: 'Agricultural College and Research Institute, Kudumiyanmalai, Pudukkottai, Tamil Nadu' },
    { name: 'Horticultural College and Research Institute for Women, Trichy, Tamil Nadu' },
    { name: 'Horticultural College and Research Institute, Periyakulam, Theni, Tamil Nadu' },
    { name: 'Agricultural College and Research Institute, Madurai, Tamil Nadu' },
    { name: 'Home Science College and Research Institute, Madurai, Tamil Nadu' },
    { name: 'Agricultural Engineering College and Research Institute, Kumulur, Trichy, Tamil Nadu' },
    { name: 'Anbil Dharmalingam Agricultural College and Research Institute, Trichy, Tamil Nadu' },
    { name: 'Agricultural College and Research Institute, Killikulam, Tirunelveli, Tamil Nadu' },
    { name: 'Agricultural College and Research Institute, Vazhavachanur, Thiruvannamalai, Tamil Nadu' },
    { name: 'Forest College and Research Institute, Mettupalayam, Tamil Nadu' },
    { name: 'Madras Veterinary College, Chennai, Tamil Nadu' },
    { name: 'Veterinary College and Research Institute, Namakkal, Tamil Nadu' },
    { name: 'Veterinary College and Research Institute, Orathanadu, Tamil Nadu' },
    { name: 'Veterinary College and Research Institute, Tirunelveli, Tamil Nadu' },
    { name: 'College of Food and Dairy Technology, Koduveli, Alamathi, Chennai, Tamil Nadu' },
    { name: 'Veterinary College and Research Institute, Thalaivasal, Salem, Tamil Nadu' },
    { name: 'Chennai Mathematical Institute, Tamil Nadu' },
    { name: 'Loyola-ICAM College of Engineering and Technology, Loyola Campus, Chennai, Tamil Nadu' },
    { name: 'Tamil Nadu Veterinary and Animal Sciences University, Chennai, Tamil Nadu' },
    { name: 'Crescent Institute of Science & Technology, Chennai, Tamil Nadu' },
    { name: 'College of Poultry Production and Management, Hosur, Tamil Nadu' },
    { name: 'Shiv Nadar University, Greater Noida, Uttar Pradesh' },
    { name: 'Indian Institute of Technology Ropar, Punjab' },
    { name: 'Central University of Punjab, Punjab' },
    { name: 'Indian Institute of Science Education and Research, Mohali, Punjab' },
    { name: 'Government Medical College, Amritsar, Punjab' },
    { name: 'Guru Govind Singh Medical College and Hospital, Faridkot, Punjab' },
    { name: 'Guru Nanak Dev Engineering College, Ludhiana, Punjab' },
    { name: 'Chitkara University, Punjab' },
    { name: 'Postgraduate Institute of Medical Education and Research, Chandigarh' },
    { name: 'Indian Institute of Technology, Palakkad, Kerala' },
    { name: 'Indian Institute of Science Education and Research, Kerala' },
    { name: 'Amal Jyothi College of Engineering, Kerala' },
    { name: 'KMEA Engineering College, Kerala' },
    { name: 'National Institute of Technology, Calicut, Kerala' },
    { name: 'Indian Institute Of Technology, Guwahati, Assam' },
    { name: 'National Institute of Technology, Guwahati (N), Assam' },
    { name: 'Indian Institute of Information Technology, Guwahati, Assam' },
    { name: 'Assam Don Bosco University, Guwahati Azara campus, Assam' },
    { name: 'Guwahati Medical College, Assam' },
    { name: 'University Of Hyderabad, Telangana' },
    { name: 'Indian School Of Business, Hyderabad' },
    { name: 'International Institute of Information Technology, Hyderabad' },
    { name: 'Tata Institute of Fundamental Research, Hyderabad' },
    { name: 'Computer Centre-Tata Institute of Fundamental Research, Hyderabad' },
    { name: 'CSIR-Centre for Cellular and Molecular Biology, Hyderabad' },
    { name: 'Osmania University, Hyderabad, Telangana' },
    { name: 'Indian Institute Of Management, Raipur, Chhattisgarh' },
    { name: 'All India Institute of Medical Sciences, Raipur, Chhattisgarh' },
    { name: 'Guru Ghasidas Vishwavidyalaya, Bilaspur, Chhattisgarh' },
    { name: 'Indian Institute Of Management, Rohtak, Haryana' },
    { name: 'Central University of Haryana' },
    { name: 'K.R. Mangalam University, Gurgaon, Haryana' },
    { name: 'THE NORTHCAP UNIVERSITY, Gurgaon, Haryana' },
    { name: 'Maharshi Dayanand University, Rohtak, Haryana' },
    { name: 'Ashoka University, Haryana' },
    { name: 'Chaudhary Ranbir Singh University, Haryana' },
    { name: 'BPS Mahila Vishwavidyalaya Khanpur Kalan, Sonepat, Haryana' },
    { name: 'Translational Health Science and Technology Institute, Faridabad, Gurgaon, Haryana' },
    { name: 'Regional Centre for Biotechnology, Faridabad, Gurgaon, Haryana' },
    { name: 'Deenbandhu Chhotu Ram University of Science and Technology, Murthal, Haryana' },
    { name: 'South Point Group of Institutions, Sonepat, Haryana' },
    { name: 'School of Engineering and Technology, K.R. Mangalam University, Gurgaon, Haryana' },
    { name: 'Management Development Institute, Gurgaon, Haryana' },
    { name: 'National Brain Research Center, Haryana' },
    { name: 'Indian Institute Of Technology, Bhubaneswar, Odisha' },
    { name: 'National Institute Of Technology, Rourkela, Odisha' },
    { name: 'Centurion University of Technology and Management, Parallakhemundi campus, Odisha' },
    { name: 'National Institute of Science Education and Research (NISER), Odisha' },
    { name: 'Centurion University, Jatni, Bhubaneswar, Odisha' },
    { name: 'Institute of Physics, Bhubaneswar, Odisha' },
    { name: 'Utkal University, Bhubaneswar, Odisha' },
    { name: 'Indian Institute of Technology Jodhpur, Rajasthan' },
    { name: 'All India Institute of Medical Sciences, Jodhpur, Rajasthan' },
    { name: 'Birla Institute of Technology and Science, Pilani, Rajasthan' },
    { name: 'Birla Institute Of Technology Mesra, Jaipur, Rajasthan' },
    { name: 'Malaviya National Institute of Technology, Jaipur, Rajasthan' },
    { name: 'Government Women Engineering College, Ajmer, Rajasthan' },
    { name: 'Rajasthan Technical University, Rajasthan' },
    { name: 'Sir Padampat Singhania University, Udaipur, Rajasthan' },
    { name: 'North Eastern Hill University, Shillong, Meghalaya' },
    { name: 'National Institute of Technology, Agartala, Tripura' },
    { name: 'Indian Institute of Technology, Jammu' },
    { name: 'University of Jammu, Jammu and Kashmir' },
    { name: 'Indian Institute of Information Technology, Manipur' },
    { name: 'Manipur University, Imphal, Manipur' },
    { name: 'D.M. College of Commerce, Imphal, Manipur' },
    { name: 'G.P. Women’s College, Imphal, Manipur' },
    { name: 'Sikkim Manipal University, Gangtok, Sikkim' },
    { name: 'All India Institute of Medical Sciences (AIIMS), Jharkhand' },
    { name: 'Birla Institute Of Technology Mesra, Lalpur, Jharkhand' },
    { name: 'Birla Institute Of Technology Mesra, Deoghar, Jharkhand' },
    { name: 'Birla Institute Of Technology Mesra, Ranchi, Jharkhand' },
    { name: 'Indian School of Mines, Dhanbad, Jharkhand' },
    { name: 'Rajendra Institute of Medical Sciences, Ranchi, Jharkhand' },
    { name: 'Indian Institute of Technology Kharagpur, West Bengal' },
    { name: 'Indian Institute of Management, Kolkata, West Bengal' },
    { name: 'Indian Institute of Science Education and Research, Kolkata, West Bengal' },
    { name: 'Indian Statistical Institute, Kolkata, West Bengal' },
    { name: 'National Institute of Technology, Durgapur, West Bengal' },
    { name: 'Variable Energy Cyclotron Center, Kolkata, West Bengal' },
    { name: 'National Institute of Electronics & Information Technology (NIELIT), Kolkata, West Bengal' },
    { name: 'AICTE Head Quarters, Delhi' },
    { name: 'Plaksha University, Punjab' },
    { name: 'Shri Shankaracharya Institute of Professional Management and Technology, Raipur' },
     { name: 'Birla Institute Of Technology Mesra, Kolkata, West Bengal' },
    { name: 'Jalpaiguri Govt. Engineering College, West Bengal' },
];


const initialRightColumnData = [
    
    { name: 'Institute Of Genetic Engineering, Kolkata, West Bengal' },
    { name: 'Neotia Institute of Technology, West Bengal' },
    { name: 'Indian Association for the Cultivation of Science, Kolkata, West Bengal' },
    { name: 'Indian Institute Of Technology, Mumbai, Maharashtra' },
    { name: 'Indian Institute of Science Education and Research, Pune, Maharashtra' },
    { name: 'National Institute Of Immunology, Mumbai, Maharashtra' },
    { name: 'The Inter-University Centre for Astronomy and Astrophysics, Pune, Maharashtra' },
    { name: 'Tata Institute of Fundamental Research (TIFR), Mumbai, Maharashtra' },
    { name: 'Savitribai Phule Pune University, Pune, Maharashtra' },
    { name: 'Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya, Wardha, Maharashtra' },
    { name: 'King Edwards Memorial Hospital, Mumbai, Maharashtra' },
    { name: 'Homi Bhabha Centre for Science Education-Tata Institute of Fundamental Research (TIFR), Mumbai, Maharashtra' },
    { name: 'National Centre for Radio Astrophysics- Tata Institute of Fundamental Research (TIFR), Pune, Maharashtra' },
    { name: 'Lokmanya Tilak Municipal Medical College, Mumbai, Maharashtra' },
    { name: 'Dept. of Biological Sciences at Tata Institute of Fundamental Research, Mumbai, Maharashtra' },
    { name: 'Veermata Jijabai Technological Institute, Mumbai, Maharashtra' },
    { name: 'Indian Institute Of Technology, Kanpur, Uttar Pradesh' },
    { name: 'Indian Institute Of Management, Lucknow, Uttar Pradesh' },
    { name: 'Birla Institute Of Technology Mesra, Noida, Uttar Pradesh' },
    { name: 'Birla Institute Of Technology Mesra, Allahabad, Uttar Pradesh' },
    { name: 'Main Campus, Banaras Hindu University, Varanasi, Uttar Pradesh' },
    { name: 'Institute of Agricultural Science, Banaras Hindu University, Varanasi, Uttar Pradesh' },
    { name: 'Institute of Medical Sciences, Banaras Hindu University, Varanasi, Uttar Pradesh' },
    { name: 'Allahabad University, Uttar Pradesh' },
    { name: 'Galgotias University, Greater Noida, Uttar Pradesh' },
    { name: 'Harish Chandra Research Institute, Allahabad, Uttar Pradesh' },
    { name: 'Babasaheb Bhimrao Ambedkar University, Lucknow, Uttar Pradesh' },
    { name: 'Centre of Bio-Medical Research (CBMR), Lucknow, Uttar Pradesh' },
    { name: 'Jagatpur PG College, Banaras (N), Uttar Pradesh' },
    { name: 'Motilal Nehru National Institute of Technology, Allahabad, Uttar Pradesh' },
    { name: 'Centre of Bio-Medical Research (CBMR), Lucknow, Uttar Pradesh' },
    { name: 'IEC College, Greater Noida, Uttar Pradesh' },
    { name: 'Indian Institute of Technology, Mandi, Himachal Pradesh' },
    { name: 'National Institute of Technology, Hamirpur, Himachal Pradesh' },
    { name: 'Indian Institute of Information Technology, Una, Himachal Pradesh' },
    { name: 'Himachal Pradesh University, Himachal Pradesh' },
    { name: 'Chitkara University, Himachal Pradesh' },
    { name: 'Indian Institute of Technology, Patna, Bihar' },
    { name: 'Computer Centre - Indian Institute of Technology Patna, Bihar' },
    { name: 'All India Institute Of Medical Sciences Patna, Bihar' },
    { name: 'Birla Institute Of Technology Mesra, Patna, Bihar' },
    { name: 'Darbhanga Medical College, Darbhanga, Bihar' },
    { name: 'Indian Institute of Technology, Roorkee, Uttarakhand' },
    { name: 'Indian Institute Of Management, Kashipur, Uttarakhand' },
    { name: 'Computer Centre - University of Petroleum & Energy Studies, Dehradun, Uttarakhand' },
    { name: 'All India Institute of Medical Sciences, Rishikesh, Uttarakhand' },
    { name: 'University of Petroleum & Energy Studies, Dehradun, Uttarakhand' },
    { name: 'Indian Institute of Technology, Indore, Madhya Pradesh' },
    { name: 'Indian Institute of Information Technology, Design and Manufacturing, Jabalpur, Madhya Pradesh' },
    { name: 'Indian Institute of Science Education and Research, Bhopal, Madhya Pradesh' },
    { name: 'Maulana Azad National Institute of Technology, Bhopal, Madhya Pradesh' },
    { name: 'Indira Gandhi National Tribal University, Amarkantak, Madhya Pradesh' },
    { name: 'National Institute of Technical Teacher’s Training and Research, Bhopal, Madhya Pradesh' },
    { name: 'Jagran Lakecity University, Bhopal, Madhya Pradesh' },
    { name: 'Indian Institute of Technology, Tirupati, Andhra Pradesh' },
    { name: 'Indian Institute of Information Technology, Sri City, Andhra Pradesh' },
    { name: 'Indian Institute of Science Education and Research, Tirupati, Andhra Pradesh' },
    { name: 'Computer Centre - Indian Institute of Science Education and Research, Tirupati, Andhra Pradesh' },
    { name: 'SVEC – Sree Vidyanikethan Engineering College, Tirupati, Andhra Pradesh' },
    { name: 'Indian Institute of Technology, Goa' },
    { name: 'Indian Institute of Technology, Delhi' },
    { name: 'National Institute of Technology, Delhi' },
    { name: 'National Institute Of Pathology, New Delhi' },
    { name: 'International Centre For Genetic Engineering And Biotechnology, New Delhi' },
    { name: 'Indian National Science Academy, New Delhi' },
    { name: 'IEC College, Greater Kailash, Delhi' },
    { name: 'Delhi Technological University, Delhi (N)' },
    { name: 'National Institute of Electronics & Information Technology (NIELIT), New Delhi (N)' },
    { name: 'Institute of Home Economics, Delhi' },
    { name: 'Ambedkar University, Delhi' },
    { name: 'Indraprastha Institute of Information Technology, Delhi' },
    { name: 'ERNET India, Delhi' },
    { name: 'Jawaharlal Nehru University, Delhi' },
    { name: 'Shri Shankaracharya Institute of Professional Management and Technology, Raipur' },
    { name: 'Guru Gobind Singh Indraprastha University, Delhi' },
    { name: 'South Asian University, Delhi' },
    { name: 'Delhi University, New Delhi' },
    { name: 'Jamia Hamdard University, New Delhi' },
    { name: 'Vivekananda College, Delhi' },
    { name: 'Shaheed Sukhdev College of Business Studies, Delhi' },
    { name: 'Ahilya Bai College Of Nursing, Delhi' },
    { name: 'Indraprastha College For Women, Delhi' },
    { name: 'Acharya Narendra Dev College, Delhi' },
    { name: 'Aditi Mahavidyalaya, Delhi' },
    { name: 'Lady Shri Ram College, Delhi' },
    { name: 'Aryabhatta College (Ram Lal College evening), Delhi' },
    { name: 'Lakshmibai College, Delhi' },
    { name: 'Atma Ram Sanatan Dharma College, Delhi' },
    { name: 'Maharaja Agrasen College, Delhi' },
    { name: 'Bhagini Nivedita College, Delhi' },
    { name: 'Maharshe Valmiki College of Education, Delhi' },
    { name: 'Maitreyi College, Delhi' },
    { name: 'Bhaskaracharya College of Applied Sciences, Delhi' },
    { name: 'Mata Sundri College, Delhi' },
    { name: 'Bhim Rao Ambedkar College, Delhi' },
    { name: 'Miranda House, Delhi' },
    { name: 'College of Vocational Studies, Delhi' },
    { name: 'Moti Lal Nehru College (Morning), Delhi' },
    { name: 'Daulat Ram College, Delhi' },
    { name: 'Motilal Nehru College (Evening), Delhi' },
    { name: 'Deen Dayal Upadhayaya College, Delhi' },
    { name: 'Pannalal Girdharlal Dayanand Anglo-Vedic College, Delhi' },
    { name: 'Delhi College of Arts & Commerce,Delhi' },
    { name: 'Pannalal Girdharlal Dayanand Anglo-Vedic College (Evening), Delhi' },
    { name: 'Deshbandhu College, Delhi' },
    { name: 'Rajdhani College, Delhi' },
    { name: 'Dyal Singh College, Delhi' },
    { name: 'Ram Lal Anand College, Delhi' },
    { name: 'Dyal Singh College (Evening), Delhi' },
    { name: 'Ramanujan College, Delhi' },
    { name: 'Gargi College, Delhi' },
    { name: 'Bharati College, Delhi' },
    { name: 'Ramjas College, Delhi' },
    { name: 'Hans Raj College, Delhi' },
    { name: 'Satyawati College, Delhi' },
    { name: 'Hindu College, Delhi' },
    { name: 'Satyawati College (Evening), Delhi' },
    { name: 'Indira Gandhi Institute of Physical Education & Sports Sciences, Delhi' },
    { name: 'Shaheed Bhagat Singh College, Delhi' },
    { name: 'Indraprastha College, Delhi' },
    { name: 'Shaheed Bhagat Singh College (Evening), Delhi' },
    { name: 'Shaheed Rajguru College of Applied Sciences, Delhi' },
    { name: 'Janki Devi Memorial College, Delhi' },
    { name: 'Jesus and Mary College, Delhi' },
    { name: 'Shivaji College, Delhi' },
    { name: 'Kalindi College, Delhi' },
    { name: 'Shri Ram College of Commerce, Delhi' },
    { name: 'Kamla Nehru College, Delhi' },
    { name: 'Shyam Lal College, Delhi' },
    { name: 'Keshav Mahavidyalaya, Delhi' },
    { name: 'Shyam Lal College (Evening), Delhi' },
    { name: 'Kirori Mal College, Delhi' },
    { name: 'Shyama Prasad Mukherjee College, Delhi' },
    { name: 'Lady Irwin College, Delhi' },
    { name: 'Sri Aurobindo College, Delhi' },
    { name: 'Sri Aurobindo College (Evening), Delhi' },
    { name: 'Swami Shradhanand College, Delhi' },
    { name: 'Sri Guru Gobind Singh College of Commerce, Delhi' },
    { name: 'St. Stephen’s College, Delhi' },
    { name: '43rd APAN INDIA, New Delhi' },
    { name: 'National Knowledge Network, Shastri Park, New Delhi' },
    { name: 'Computer Centre North Campus, Delhi University, Delhi' },
    { name: 'Indian Statistical Institute, New Delhi' },
    { name: 'Bhai Parmanand Institute Of Business Studies, Delhi' },
    { name: 'Shri G.S. Institute of Technology and Science, Indore (MP)' },
    { name: 'Indian Institute of Science Education and Research, Mohali, Punjab' },
    { name: 'National Institute of Advanced Studies, Bangalore, Karnataka' },
    { name: 'Shri Lal Bahadur Shastri Sanskrit University, Delhi' },
    { name: 'Veer Narmad South Gujarat University' },
];

  
    const [leftColumnData, setLeftColumnData] = React.useState(initialLeftColumnData);
    const [rightColumnData, setRightColumnData] = React.useState(initialRightColumnData);
  const location =useLocation();
  return (
    <>
    <Nav/>
    <h1 className="text">Indian Presence</h1>
          <hr></hr>
          <p className="text01">The following campuses are eduroam enabled and upcomings :-</p>
          <div className="containerIndian">
      <div className="left-column">
        
        <TableComponentLeft leftColumnData={leftColumnData}  />
      </div>
      <div className="right-column">
        
        <TableComponentRight  rightColumnData={rightColumnData} />
      </div>
      
    </div>
      <Footer/>
    </>
  );
}

export default Indian;
