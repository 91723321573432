import React from "react";
import './Footer.css';

const Footer=()=>{
    return(
        <div>
            <footer className="footer">
        <div className="footer-content">
          <p>&copy; Eduroam - ERNET India | 2024 All Rights Reserved
          </p>
        </div>
      </footer>
        </div>

    )
}
export default Footer;