import React from "react";
import { Link, useLocation  } from "react-router-dom";
import "./Contact.css";
import Nav from "../Nav/Nav";


const Contact = () => {
  const location =useLocation();
  return (
    <>
      <Nav/>
      <div>
        <h1>CONTACT </h1>
        <p className="p">Please contact below for joining eduroam network.</p>
        <hr></hr>

        <p className="p">Eduroam Help Desk</p>

        <p className="contact"><strong>Phone</strong> <span>: 011-22170641</span></p>
        <p className="contact"><strong>Email</strong><span>: </span><a href="mailto:eduroam@ernet.in">eduroam@ernet.in</a></p>
        <hr></hr>
        <hr></hr>

        <p className="p">Mr. Mohd Owais <br></br>Additional Director (Technical)</p>

        <p className="contact"><strong>Phone</strong> <span>: +91-11-22170640</span></p>
        <p className="contact"><strong>Email</strong><span>: </span><a href="mailto:owais@ernet.in">owais@ernet.in</a></p>
        <hr></hr>

        <p className="p">Mr. Srikanth Mallepakula <br></br>Scientific Officer 'SB'</p>

        <p className="contact"><strong>Phone</strong> <span>: +91-11-22170642</span></p>
        <p className="contact"><strong>Email</strong><span>: </span><a href="mailto:srikanth@ernet.in">srikanth@ernet.in</a></p>
        


        <p className="contact">
           <strong>Address : ERNET India,</strong>
        </p>
        <p className="address">Block-I, A Wing, 5th Floor<br></br>
        DMRC IT Park, Shastri Park<br></br>
        Delhi-110053</p>

      </div>
      <footer className="footer">
        <div className="footer-content-1">
          <p>&copy; Eduroam - ERNET India | 2024 All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};
export default Contact;
