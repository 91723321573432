import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./detail.css";
import Nav from "../Nav/Nav";

const RegistrationDetails = () => {
    const location = useLocation();
    const { user } = location.state;

    const firstName = user.name.substring(0, 3);
    const dateOfBirth = new Date(user.date_of_birth);
    const day = String(dateOfBirth.getDate()).padStart(2, '0');
    const month = String(dateOfBirth.getMonth() + 1).padStart(2, '0');
    const year = String(dateOfBirth.getFullYear());
    const lastThreeDigits = user.phone_number.slice(-3);
    const password = firstName + day + month + year + lastThreeDigits;

    useEffect(() => {
        // Store user data in sessionStorage
        sessionStorage.setItem("userData", JSON.stringify(user));

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [user]);

    return (
        <>
            <Nav />
            <h1>User Detail</h1>
            <h3 className="h3">Please take a picture or note your details for uninterrupted Eduroam Services.</h3>
            <div className="containerA">
                <div className="section">
                    <div className="details">
                        <p>Name: {user.name}</p>
                        <p>Email: {user.email}</p>
                        <p>Password: {password}</p>
                        <p>Student Id/Faculty Id: {user.userId}</p>
                        <p>Department: {user.department}</p>
                        <p>Phone Number: {user.phone_number}</p>
                        <p>Date of Birth: {user.date_of_birth}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationDetails;
