// Navigation.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nav.css'; 

const Navigation = () => {
  return (
    <Navbar expand="lg" bg="dark" variant="dark" className="navbar">
      <Container>
        <Navbar.Brand as={NavLink} to="/" className="navbar-logo">
          <img
            src={require("../images/mainlogo.png")}
            alt="Logo"
            className="logo-image"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className='navbar-toggle'/>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="nav-list ml-auto">
            <li className="nav-item">
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link" activeClassName="active">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/testimonial" className="nav-link" activeClassName="active">
                Testimonial
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/indianpresence" className="nav-link" activeClassName="active">
                Indian Presence
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/techsupport" className="nav-link" activeClassName="active">
                Tech-Support
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link" activeClassName="active">
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/requestform" className="nav-link" activeClassName="active">
                Request Form
              </NavLink>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
